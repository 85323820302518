import { ResponsiveImage } from "@/comps/image"
import { FlexItem } from "react-styled-flex"
import { z } from "zod"

import { assetUrl } from "@/utils/cdn"
import clsx from "clsx"
import { useState } from "react"
import Tooltip from "../tooltip"

export const reviewSchema = z.object({
  name: z.string(),
  opinion: z.string(),
  rating: z.number(),
  avatar: z.string(),
})

export const reviewComponent = z.object({
  component: z.literal("reviews"),
  title: z.string(),
  subtitle: z.string().optional(),
  without_background: z.boolean().optional(),
  reviews: z.array(reviewSchema),
})

export type Reviews = z.infer<typeof reviewSchema>[]
type Review = z.infer<typeof reviewSchema>
type ReviewProps = z.infer<typeof reviewComponent>

export default function ReviewsLanding(props: ReviewProps) {
  const { reviews, title, subtitle, without_background } =
    props
  const [offset, setOffset] = useState(0)

  return (
    <>
      <div className="relative w-full py-[35px] tablet:py-[40px] desktop:py-[55px]">
        {!without_background && (
          <div className="pointer-events-none absolute right-0 top-0 w-full -translate-y-1/4 overflow-hidden">
            <ResponsiveImage
              src="/feature/background"
              className="w-full object-fill dark:opacity-40"
            />
          </div>
        )}
        <div className="relative  flex w-full flex-col items-center gap-4 text-center tablet:gap-[26px]">
          <h2 className="text-[26px] font-800 leading-tight text-blue-900 tablet:text-[26px] desktop:text-[52px]">
            {title}
          </h2>
          <p className="text-body-3 desktop:max-w-[800px]">
            {subtitle}
          </p>
          <div className="relative hidden w-[1165px] flex-row desktop:flex desktop:items-center desktop:justify-center desktop:gap-3">
            <button
              disabled={offset === 0}
              onClick={() => setOffset(offset - 1)}
              className="absolute left-0 z-20 hidden h-[50px] w-[50px] -translate-x-1/2 rotate-180 flex-row items-center justify-center rounded-full bg-color-cell shadow-2xl shadow-[#B7B7B70C] transition-colors inner-border inner-border-color-separator hover:bg-blue-100 disabled:opacity-0 desktop:flex">
              <Arrow />
            </button>
            <div className="relative z-0 w-full desktop:h-[350px]">
              <div
                className={`no-scrollbar absolute left-0 top-0 hidden w-full flex-row gap-5 overflow-x-scroll px-4 tablet:gap-4 tablet:px-5 desktop:flex desktop:h-[550px] desktop:w-full desktop:gap-6 desktop:overflow-x-hidden desktop:px-0`}>
                <div
                  style={{ left: `-${offset * 395}px` }}
                  className="absolute top-0 hidden gap-5 transition-all duration-300 desktop:flex desktop:flex-row">
                  {reviews.map((review, index) => (
                    <SingleReviewDesktop
                      key={index}
                      review={review}
                      without_background={
                        without_background
                      }
                      avatar={review.avatar}
                    />
                  ))}
                </div>
              </div>
            </div>
            <button
              disabled={offset + 3 === reviews.length}
              onClick={() => setOffset(offset + 1)}
              className="absolute right-0 z-20 hidden h-[50px] w-[50px] translate-x-1/2 flex-row items-center justify-center rounded-full bg-color-cell shadow-2xl shadow-[#B7B7B70C] transition-colors inner-border inner-border-color-separator hover:bg-blue-100 disabled:opacity-0 desktop:flex">
              <Arrow />
            </button>
          </div>
          <div className="no-scrollbar flex w-full flex-row gap-5 overflow-x-scroll px-4 tablet:gap-4 tablet:px-5 desktop:hidden">
            {reviews.map((review, index) => (
              <SingleReview
                key={index}
                review={review}
                without_background={without_background}
                avatar={review.avatar}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

export function SingleReview(props: {
  review: Review
  avatar: string
  without_background?: boolean
}) {
  const { avatar, review, without_background } = props
  const { name, rating, opinion } = review
  return (
    <>
      <div
        className={clsx(
          "relative z-20 flex shrink-0 flex-col items-center gap-4 rounded-[12px] border border-color-separator px-5 py-4 tablet:px-[26px] tablet:py-10 desktop:w-[375px]",
          without_background
            ? "bg-blue-cell"
            : "bg-color-cell"
        )}>
        <img
          src={avatar}
          alt={name}
          className="h-[62px] w-[62px] rounded-full object-cover tablet:h-[122px] tablet:w-[122px] desktop:h-[62px] desktop:w-[62px]"
        />
        <p className=" text-2xl font-700">{name}</p>
        <FlexItem box gap={8} alignSelf="center">
          {Array(5)
            .fill(0)
            .map((_, i) =>
              i < rating ? (
                <img
                  src={assetUrl("/comps/star-full.svg")}
                  alt="star icon"
                  key={i}
                />
              ) : (
                <img
                  src={assetUrl("/comps/star-empty.svg")}
                  alt="star icon"
                  key={i}
                />
              )
            )}
        </FlexItem>

        <p className="line-clamp-3 w-[229px] px-2 text-base font-400 text-color-placeholder tablet:px-0  desktop:w-[350px]">
          {`“${opinion}”`}
        </p>
      </div>
    </>
  )
}

export function SingleReviewDesktop(props: {
  review: Review
  avatar: string
  without_background?: boolean
}) {
  const { avatar, review, without_background } = props
  const { name, rating, opinion } = review
  return (
    <>
      <div
        className={clsx(
          "relative z-20 flex shrink-0 flex-col items-center gap-4 rounded-[12px] border border-color-separator px-5 py-4 tablet:px-[26px] tablet:py-10 desktop:w-[375px]",
          without_background
            ? "bg-blue-cell"
            : "bg-color-cell"
        )}>
        <img
          src={avatar}
          alt={name}
          className="h-[62px] w-[62px] rounded-full object-cover tablet:h-[122px] tablet:w-[122px] desktop:h-[62px] desktop:w-[62px]"
        />
        <p className=" text-2xl font-700">{name}</p>
        <FlexItem box gap={8} alignSelf="center">
          {Array(5)
            .fill(0)
            .map((_, i) =>
              i < rating ? (
                <img
                  src={assetUrl("/comps/star-full.svg")}
                  alt="star icon"
                  key={i}
                />
              ) : (
                <img
                  src={assetUrl("/comps/star-empty.svg")}
                  alt="star icon"
                  key={i}
                />
              )
            )}
        </FlexItem>
        <Tooltip
          className="w-[229px] px-2 tablet:px-0 desktop:w-full"
          showAlways={opinion.length > 165}
          title={`“${opinion}”`}>
          <p className=" adesktop:w-[350px] aw-[229px] line-clamp-4 w-full text-base font-400  text-blue-600">
            {`“${opinion}”`}
          </p>
        </Tooltip>
      </div>
    </>
  )
}

const Arrow = () => (
  <svg
    width="11"
    height="18"
    viewBox="0 0 11 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M1.56961 1.36114L9.2085 9.00003L1.56961 16.6389"
      stroke="#575757"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
